export const Helper = {
    methods:{

        tes(){
            alert("as");
            return "asd";
        },

        numberWithCommas(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },

        emObj(obj) {
            if( obj == null ){
                return true;
            }
            return Object.keys(obj).length === 0;
        },

        
        vadFile(fname){
            console.log(fname);
            if( fname == "application/vnd.ms-excel" || fname == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ){
                return true;
            }else{
                return false;
            }
        },
       
        
        timeDifference(previous) {
    
            let msPerMinute = 60 * 1000;
            let msPerHour = msPerMinute * 60;
            let msPerDay = msPerHour * 24;
            let msPerMonth = msPerDay * 30;
            let msPerYear = msPerDay * 365;

            let dtUTC = new Date(previous)
            let dateNow = new Date(new Date().toISOString().replace("T"," "));
            console.log(dtUTC)
            console.log(dateNow)

            let elapsed = dateNow - dtUTC;
            
            if (elapsed < msPerMinute) {
                 return Math.round(elapsed/1000) + ' Detik lalu';   
            }
            
            else if (elapsed < msPerHour) {
                 return Math.round(elapsed/msPerMinute) + ' Menit lalu';   
            }
            
            else if (elapsed < msPerDay ) {
                 return Math.round(elapsed/msPerHour ) + ' Jam lalu';   
            }
        
            else if (elapsed < msPerMonth) {
                 return 'Sekitar ' + Math.round(elapsed/msPerDay) + ' Hari lalu';   
            }
            
            else if (elapsed < msPerYear) {
                 return 'Sekitar ' + Math.round(elapsed/msPerMonth) + ' Bulan Lalu';   
            }
            
            else {
                 return 'Sekitar ' + Math.round(elapsed/msPerYear ) + ' Tahun Lalu';   
            }
        },
        
        getLogin(){
            // Retrieves the user token from localStorage
            try {
                let data = this.cookies.get("token")
                if( data == null || typeof data === 'undefined')
                {
                    data = sessionStorage.getItem("token");
                    if( data == null || typeof data === 'undefined')
                    {
                        return null
                    }
                }
                return JSON.parse(data);
            } catch (error) {
                return null;
            }
        },

        snackBar(color, text){
            this.snackbar=true
            this.snackbar_text=text
            this.snackbar_color=color
        },
    
        callLogOut(){
            localStorage.clear();
            this.$router.push('/login');
        },

        wrongToken(msg){
            if( msg == "Wrong Token" ){
                sessionStorage.clear();
                this.$router.push('/');
            }else{
                console.log(msg)
            }
        },

        wrongTokenCo(res){
            if( res.data.msg == "Wrong Token" ){
               
                localStorage.clear();
                this.$router.push('/login');
            }else{
                console.log(res)
            }
        },

        cekData(data, sub = null){
            try {
                let a = null
                if(sub == null){
                    if( data == null || data == '' || data == undefined ){
                        a = false
                    }else{
                        a = true
                    }
                }else{
                    if( this.getNested(data, sub) == null || 
                    this.getNested(data, sub) == '' || 
                    this.getNested(data, sub) == undefined ){
                        a = false
                    }else{
                        a = true
                    }
                }

                return a
               
            } catch (error) {
                return false
            }
        },
       
        getNested (theObject, path, separator) {
            try {
                separator = separator || '.';
            
                let d = path.
                        replace('[', separator).replace(']','').
                        split(separator).
                        reduce(
                            function (obj, property) { 
                                return obj[property];
                            }, theObject
                        );
                
                if(d == false){
                    return '' 
                }
                if(d == null ){ 
                    return '' 
                }
                if(d == undefined ){ 
                    return '' 
                }

                return d
                            
            } catch (err) {
                return '';
            }   
        },

        cleanObject(obj, id=false) {
            for (var propName in obj) {
              if (obj[propName] === null || obj[propName] === undefined) {
                delete obj[propName];
              }
            }

            if(obj["id"] === "" && id == true){
                delete obj["id"];
            }

            return obj
        },
          

        cekFalse(data){
            if(data == false || data == 'false'){
                return ''
            }else{
                return data
            }
        },
        dataDateTimeAPI(data){
            let dateOrder = data;
            dateOrder = dateOrder.split(".");
            let devdat_replace =  dateOrder[0].replace("T", " ");
            return devdat_replace
        },
        cekDataNominal(data, data1){
            try {
                return Intl.NumberFormat().format( this.getNested(data, data1) )
            } catch (error) {
                return 0
            }
        },
        validEmail: function (email) {
            var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
          }
    }
}