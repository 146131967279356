
// level 1 : Project Manager
// Level 2 : Manager
// Level 3 : Direksi

export const menuItems = {
    level1:[
        {
            id: 1,
            label: 'karyaMenuItems.dashboard.text',
            icon: 'ri-pie-chart-2-fill',
            link: '/'
        },
        {
            id: 3,
            label: 'karyaMenuItems.clientAssesment.text',
            icon: 'ri-file-paper-line',
            link: '/view-client-assesment'
        },
        {
            id: 2,
            label: 'karyaMenuItems.PublicAssesment.text',
            icon: 'ri-database-fill',
            link: '/view-public-assesment'
        },
    ],
    level2:[
        {
            id: 2,
            label: 'karyaMenuItems.dashboard.text',
            icon: 'ri-pie-chart-2-fill',
            link: '/'
        },
        {
            id: 201,
            label: 'karyaMenuItems.clientAssesment.text',
            icon: 'ri-file-paper-line',
            link: '/view-client-assesment'
        },
        {
            id: 200,
            label: 'karyaMenuItems.PublicAssesment.text',
            icon: 'ri-database-fill',
            link: '/view-public-assesment'
        },
      
    ],
    level3:[
        {
            id: 3,
            label: 'karyaMenuItems.dashboard.text',
            icon: 'ri-pie-chart-2-fill',
            link: '/'
        },
        {
            id: 301,
            label: 'karyaMenuItems.PublicAssesment.text',
            icon: 'ri-database-fill',
            link: '/view-public-assesment'
        },
    ]
}
