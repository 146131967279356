<style>
.navbar-nav.custom {
  flex-direction: row;
  gap: 40px;
}
.navbar-nav.custom a {
  color: #4d4d4d;
  font-size: 16px;
}
.navbar-nav.custom li.active a {
  border-bottom: 3px solid #4d4d4d;
}
</style>
<script>
// import simplebar from "simplebar-vue";
import i18n from "../i18n";
import { layoutComputed } from "@/state/helpers";
import { menuItems } from "./horizontal-menu";
import Swal from "sweetalert2";
import axios from "axios";
import Config from "../config.js";

export default {
  props: {
    type: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...layoutComputed,
  },

  data() {
    return {
      dismissSecs: 10,
      dismissCountDown: 0,
      dissmissMsg: "",

      dataNotif: [],
      form: {
        pass0: "",
        pass1: "",
        pass2: "",
      },
      data_url: Config,
      dataTime: null,
      notif: false,
      dataLogin: {
        ad_user_id: "",
        ad_user_name: "",
        token: "",
      },
      flagsrc: require("@/assets/images/flags/us.jpg"),
      languages: [
        {
          flag: require("@/assets/images/flags/us.jpg"),
          language: "en",
          title: "English",
        },
        {
          flag: require("@/assets/images/flags/id.png"),
          language: "id",
          title: "Indonesia",
        },
      ],
      current_language: "en",
      pathNow: "",
      userStart: JSON.parse(localStorage.getItem("user")),
      menuItems: menuItems.level1,
    };
  },
  methods: {
    cancel() {
      (this.form = {
        pass0: "",
        pass1: "",
        pass2: "",
      }),
        this.$bvModal.hide("modal-1");
    },
    async okCP() {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-success ml-2",
        },
        buttonsStyling: false,
      });



      let d = this.form.pass1.includes("*");
      if (this.form.pass1 != this.form.pass2) {
        this.dissmissMsg = "Password and Confirm Password Not Same";
        this.showAlert();
      } else if (
        this.form.pass1 == "" ||
        this.form.pass0 == "" ||
        this.form.pass2 == ""
      )  {
        this.dissmissMsg = "Please fill, All Field";
        this.showAlert();
      } else if ( d == true ){
          this.dissmissMsg = "Please, don't use character * (Star)";
        this.showAlert();
      } else {
        let formData = new URLSearchParams();
        formData.append("current_password", this.form.pass0);
        formData.append("new_password", this.form.pass1);

        await axios
          .post(
            process.env.VUE_APP_AUTHDOMAIN + this.data_url.changePassword,
            formData,
            {
              headers: {
                "Forca-Token": this.userStart.token,
                Accept: "application/json",
              },
            }
          )
          .then(async (res) => {
            if (Config.modeDev) console.log(res);
            if (res.data.codestatus == "S") {
              await swalWithBootstrapButtons.fire(
                "Success, Password Changed !",
                "App will be auto logout and login with new password",
                "success"
              );
              this.logout();
            } else {
              this.wrongTokenCo(res);

              this.dissmissMsg = res.data.msg;
              this.showAlert();
            }
          })
          .catch((error) => {
            if (this.is_runTest == false) {
              console.log(error);
            }

            try {
              if (error.response.status === 401) {
                this.callLogOut();
              }
            } catch (error) {
              if (this.is_runTest == false) {
                console.log(error);
              }
            }
          });
      }
    },

    async logout() {
      await axios.post(
        process.env.VUE_APP_AUTHDOMAIN + this.data_url.logout,
        null,
        {
          headers: {
            "Forca-Token": this.userStart.token,
          },
        }
      );

      this.$router.push("/logout2");
    },

    async goToHelpdesk() {
      this.$router.push("/help-desk");
    },

    async getNotif() {
      let formData = new URLSearchParams();
      formData.append("limit", "5");

      await axios
        .post(
          process.env.VUE_APP_AUTHDOMAIN + this.data_url.getNotification,
          formData,
          {
            headers: {
              "Forca-Token": this.userStart.token,
              Accept: "application/json",
              // "Content-Type": "multipart/form-data", // "application/x-www-form-urlencoded" // "text/html"
            },
          }
        )
        .then((res) => {
          if (Config.modeDev) console.log(res);
          if (res.data.codestatus == "S") {
            this.dataNotif = res.data.resultdata;
            this.notif = false;
            for (let i = 0; i < this.dataNotif.length; i++) {
              if (this.dataNotif[i].is_read == "N") {
                this.notif = true;
                break;
              }
            }
          } else {
            this.wrongTokenCo(res);
          }
        })
        .catch((error) => {
          if (this.is_runTest == false) {
            console.log(error);
          }

          try {
            if (error.response.status === 401) {
              this.logout();
            }
          } catch (error) {
            if (this.is_runTest == false) {
              console.log(error);
            }
          }
        });
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs;
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    goAssesment(v) {
      sessionStorage.setItem("params", JSON.stringify(v));
      this.$router.push({ name: "NavigatorSame" });

      let formData = new URLSearchParams();
      formData.append("id", v.id);
      formData.append("is_read", "Y");

      axios.post(
        process.env.VUE_APP_AUTHDOMAIN + this.data_url.readNotification,
        formData,
        {
          headers: {
            "Forca-Token": this.userStart.token,
          },
        }
      );
    },
    initFullScreen() {
      document.body.classList.toggle("fullscreen-enable");
      if (
        !document.fullscreenElement &&
        /* alternative standard method */ !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(
            Element.ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },
    toggleRightSidebar() {
      this.$parent.toggleRightSidebar();
    },
    toggleMenu() {
      let element = document.getElementById("topnav-menu-content");
      element.classList.toggle("show");
    },
    setLanguage(locale) {
      console.log(locale);
      this.flagsrc = locale.flag;
      i18n.locale = locale.language;
      this.current_language = i18n.locale;
    },
    hasItems(item) {
      return item.subItems !== undefined ? item.subItems.length > 0 : false;
    },
  },

  beforeDestroy() {
    clearInterval(this.timer);
  },
  mounted() {
    this.getNotif();
    this.timer = setInterval(() => {
      this.getNotif();
    }, 10000);

    this.pathNow = window.location.pathname;

    if (this.userStart.role.code == "3") {
      this.menuItems = menuItems.level3;
    }

    if (this.userStart.role.code == "2") {
      this.menuItems = menuItems.level2;
    }

    if (this.userStart.role.code == "1") {
      this.menuItems = menuItems.level1;
    }

    this.dataLogin = JSON.parse(localStorage.getItem("user"));
    this.value = this.languages.find((x) => x.language === i18n.locale);
    this.text = this.value.title;
    this.flag = this.value.flag;
  },
  watch: {
    type: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          switch (newVal) {
            case "dark":
              document.body.setAttribute("data-topbar", "dark");
              break;
            case "light":
              document.body.setAttribute("data-topbar", "light");
              document.body.removeAttribute("data-layout-size", "boxed");
              break;
            case "colored":
              document.body.setAttribute("data-topbar", "colored");
              document.body.removeAttribute("data-layout-size", "boxed");
              break;
            default:
              document.body.setAttribute("data-topbar", "dark");
              break;
          }
        }
      },
    },
    width: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          switch (newVal) {
            case "boxed":
              document.body.setAttribute("data-layout-size", "boxed");
              break;
            case "fluid":
              document.body.setAttribute("data-layout-mode", "fluid");
              document.body.removeAttribute("data-layout-size");
              break;
            default:
              document.body.setAttribute("data-layout-mode", "fluid");
              break;
          }
        }
      },
    },
  },
};
</script>

<template>
  <header id="page-topbar">
    <b-modal id="modal-1" title="Change Password" @hide="cancel" hide-footer>
      <div>
        <b-alert
          :show="dismissCountDown"
          dismissible
          variant="danger"
          @dismissed="dismissCountDown = 0"
          @dismiss-count-down="countDownChanged"
        >
          <p>
            {{ dissmissMsg }}. <br />
            <span style="font-size: 10px"
              >Dismiss {{ dismissCountDown }} seconds...</span
            >
          </p>
          <b-progress
            variant="warning"
            :max="dismissSecs"
            :value="dismissCountDown"
            height="4px"
          ></b-progress>
        </b-alert>

        <b-form>
          <b-form-group
            id="input-group-1"
            label="Current Password *"
            label-for="input-1"
            description="Please, input current password."
          >
            <b-form-input
              id="input-1"
              v-model="form.pass0"
              type="password"
              placeholder="Enter Current Password"
              required
            ></b-form-input>
          </b-form-group>
        </b-form>

        <b-form>
          <b-form-group
            id="input-group-1"
            label="New Password *"
            label-for="input-1"
            description="Input new password"
          >
            <b-form-input
              id="input-1"
              v-model="form.pass1"
              type="password"
              placeholder="Enter password"
              required
            ></b-form-input>
          </b-form-group>
        </b-form>

        <b-form>
          <b-form-group
            id="input-group-1"
            label="Confirm Password *"
            label-for="input-1"
            description="We'll never share your email with anyone else."
          >
            <b-form-input
              id="input-1"
              v-model="form.pass2"
              type="password"
              placeholder="Enter Confirm password"
              required
            ></b-form-input>
          </b-form-group>
        </b-form>

        <b-button type="reset" variant="danger" @click="cancel"
          >Cancel</b-button
        >
        <b-button
          type="submit"
          variant="primary"
          @click="okCP"
          style="float: right"
          >Change Password</b-button
        >
      </div>
    </b-modal>

    <div class="navbar-header">
      <div class="d-flex">
        <!-- LOGO -->
        <div class="navbar-brand-box">
          <span class="logo logo-dark">
            <span class="logo-sm">
              <img src="@/assets/images/logo-dark.png" alt height="22" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/logo-dark.png" alt height="20" />
            </span>
          </span>

          <span class="logo logo-light">
            <span class="logo-sm">
              <img src="@/assets/images/logo-sm-light.png" alt height="22" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/logo-light.png" alt height="20" />
            </span>
          </span>
        </div>

        <button
          type="button"
          class="btn btn-sm px-3 font-size-24 d-lg-none header-item"
          data-toggle="collapse"
          @click="toggleMenu"
        >
          <i class="ri-menu-2-line align-middle"></i>
        </button>
      </div>
      <div class="d-flex">
        <div>
          <ul class="navbar-nav custom" style="">
            <template v-for="(item, index) of menuItems">
              <li
                :class="item.link === pathNow ? 'nav-item active' : 'nav-item'"
                :key="index"
              >
                <router-link
                  tag="a"
                  v-if="!item.subItems"
                  :to="item.link"
                  class="nav-link"
                  style="font-size: 15; font-weight: bold"
                >
                  {{ $t(item.label) }}
                </router-link>
              </li>
            </template>
          </ul>
        </div>
      </div>

      <div class="d-flex">
        <div class="dropdown d-inline-block d-lg-none ml-2">
          <button
            type="button"
            class="btn header-item noti-icon waves-effect"
            id="page-header-search-dropdown"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i class="ri-search-line"></i>
          </button>
          <div
            class="dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"
            aria-labelledby="page-header-search-dropdown"
          >
            <form class="p-3">
              <div class="form-group m-0">
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Search ..."
                  />
                  <div class="input-group-append">
                    <button class="btn btn-primary" type="submit">
                      <i class="ri-search-line"></i>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>

        <b-dropdown
          right
          menu-class="dropdown-menu-lg p-0"
          toggle-class="header-item noti-icon"
          variant="black"
        >
          <template v-slot:button-content>
            <i class="ri-notification-3-line"></i>
            <span v-if="notif" class="noti-dot"></span>
          </template>

          <div class="p-3">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="m-0">
                  {{ $t("navbar.dropdown.notification.text") }}
                </h6>
              </div>
              <!-- <div class="col-auto">
                <a href="#!" class="small">{{
                  $t("navbar.dropdown.notification.subtext")
                }}</a>
              </div> -->
            </div>
          </div>

          <span
            v-for="(v, i) in dataNotif"
            class="text-reset notification-item"
            v-bind:key="i"
            @click="goAssesment(v)"
          >
            <div class="media" style="cursor: pointer">
              <div class="avatar-xs mr-3">
                <span
                  v-if="v.is_read == 'N'"
                  class="avatar-title bg-success rounded-circle font-size-16"
                >
                  <i class="ri-checkbox-circle-line"></i>
                </span>
                <span
                  v-if="v.is_read == 'Y'"
                  class="avatar-title bg-secondary rounded-circle font-size-16"
                >
                  <i class="ri-checkbox-circle-line"></i>
                </span>
              </div>
              <div class="media-body">
                <h6 class="mt-0 mb-1" style="color: #000000">New Assessment</h6>
                <div class="text-muted">
                  <p class="mb-1" style="font-size: 14px">
                    {{ v.data_notif.length }} New Assessment(s) has been added
                    to the system
                  </p>
                  <p class="mb-0">
                    <i class="mdi mdi-calendar"></i>
                    {{ v.date_notif }}
                  </p>
                </div>
              </div>
            </div>
          </span>
        </b-dropdown>

        <b-dropdown
          right
          variant="black"
          toggle-class="header-item"
          class="d-inline-block user-dropdown"
        >
          <template v-slot:button-content>
            <img
              class="rounded-circle header-profile-user"
              src="@/assets/images/users/avatar-2.jpg"
              alt="Header Avatar"
            />
            <span class="d-none d-xl-inline-block ml-1">{{
              dataLogin.ad_user_name
            }}</span>
            <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
          </template>

          <span
            class="dropdown-item"
            style="cursor: pointer"
            @click="goToHelpdesk"
          >
            <img src="@/assets/images/helpdesk.png" width="16" />
            &nbsp;Helpdesk
          </span>

          <span class="dropdown-item" v-b-modal.modal-1 style="cursor: pointer">
            <div>
              <i class="ri-lock-unlock-line align-middle mr-1"></i>
              Change Password
            </div>
          </span>

          <div class="dropdown-divider"></div>
          <span
            class="dropdown-item text-danger"
            style="cursor: pointer"
            @click="logout"
          >
            <i class="ri-shut-down-line align-middle mr-1 text-danger"></i>
            {{ $t("navbar.dropdown.kevin.list.logout") }}
          </span>
        </b-dropdown>
      </div>
    </div>
  </header>
</template>
